export function equalArray(array1: string[] | undefined, array2: string[] | undefined): boolean {
    if (array1 === null || array1 === undefined || array1.length === 0
         || array2 === null || array2 === undefined || array2.length === 0) {
            return false
    }

    for(let word of array1) {
        if (!array2.includes(word)) { 
            return false
        }
    }

    return true
}

// export type Move = 'down' | 'up' | 'left' | 'right' | 'diagonal' | 'empty'
export function getMove(num: string) {
    const moveNum = +num
    if (moveNum % 5 === 0) {
        return 'down'
    } else if (moveNum % 5 === 1) {
        return 'up'
    } else if (moveNum % 5 === 2) {
        return 'left'
    } else if (moveNum % 5 === 3) {
        return 'right'
    } else if (moveNum % 5 === 4) {
        return 'diagonal'
    } else {
        return 'empty'
    }
}