import { getIsUnlimitedMode } from '../App'
import { UAParser } from 'ua-parser-js'

import { getCounter, getStoredRestart, getTitle } from './localStorage'
import { getToday } from './dateutils'
import { getTranslation } from '../context/messages'
import { TranslationKey } from '../constants/strings'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  handleShareToClipboard: () => void,
  handleShareFailure: () => void,
) => {
  var textToShare = `${getTitle()} ${getIsUnlimitedMode() ? '' : getToday().toDateString()}\n`
  textToShare = textToShare + getTranslation(TranslationKey.RESTART) + ': ' 
  textToShare = textToShare + `${getStoredRestart() ? '✔️' : '❌'}\n`
  textToShare = textToShare + 'Moves: ' + getCounter() + '\n\n'
  textToShare = textToShare + 'https://enigmagame.ca'
  
  const shareData = { text: textToShare }
  let shareSuccess = false
  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure)
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}
