import {
  addDays,
  startOfDay,
} from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import { WORDS, WORDS_DE, WORDS_ES, WORDS_FR, WORDS_HORSLE, WORDS_PT } from '../constants/wordlist'
import { getToday } from './dateutils'
import { Language } from '../constants/language'
import { getCounter, getLanguage} from './localStorage'
import { getIsHorsle, getIsSpeedy } from '../App'
import { isSpeedyGameDone } from './speedy'
import { Move } from './statuses'

// 1 January 2022 Game Epoch
export const FIRST_GAME_DATE = new Date(2022, 5, 1)

export const getWords = () => {
  if(getIsHorsle()){
    return WORDS_HORSLE
  }

  if(getLanguage() === Language.PORTUGUESE){
    return WORDS_PT
  }

  if(getLanguage() === Language.FRENCH){
    return WORDS_FR
  }

  if(getLanguage() === Language.SPANISH){
    return WORDS_ES
  }

  if(getLanguage() === Language.GERMAN){
    return WORDS_DE
  }

  return WORDS
}

export function addToPresent(
  letter: string,
  tempPresent: { [letter: string]: number }
): void {
  if (letter in tempPresent) {
    tempPresent[letter]++
  } else {
    tempPresent[letter] = 1
  }
}

export function mergePresent(present: { [letter: string]: number }, tempPresent: { [letter: string]: number }): void {
  for (const letter in tempPresent) {
    if ((letter in present && tempPresent[letter] > present[letter]) ||
      !(letter in present)) {
      present[letter] = tempPresent[letter]
    }
  }
}

export function allPresentLettersIn(present: { [letter: string]: number }, word: string): [boolean, string] {
  for (const letter in present) {
    if (countLettersInWord(letter, word) < present[letter]) {
      return [false, letter]
    }
  }

  return [true, '']
}

export function countLettersInWord(letter: string, word: string): number {
  let count = 0
  for (const wordLetter of word) {
    if (wordLetter === letter) {
      count++
    }
  }

  return count
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

function getTimeFromDate(date: Date): number {
  return date.getTime() - (date.getTimezoneOffset() * 60 * 1000)
}

export const getIndex = (gameDate: Date) => {
  var diff = Math.abs(getTimeFromDate(gameDate) - getTimeFromDate(FIRST_GAME_DATE));
  const defaultIndex = Math.ceil(diff / (1000 * 3600 * 24)) - 1; 

  if(getIsSpeedy()){
    return defaultIndex * 7000
  } else if(getIsSpeedy()){
    return defaultIndex * 70
  }

  return defaultIndex * 5
}

const getWordsOfDay = (index: number) => {
  return [getWordOfDay(index * 5), 
          getWordOfDay(index * 5 + 1),
          getWordOfDay(index * 5 + 2),
          getWordOfDay(index * 5 + 3),
          getWordOfDay(index * 5 + 4)]
}

const getWordOfDay = (index: number) => {
  if (index < 0) {
    throw new Error('Invalid index')
  }
  if(getIsHorsle()){
    return localeAwareUpperCase(WORDS_HORSLE[index % WORDS_HORSLE.length])
  }

  if(getLanguage() === Language.PORTUGUESE){
    return localeAwareUpperCase(WORDS_PT[index % WORDS_PT.length])
  }

  if(getLanguage() === Language.FRENCH){
    return localeAwareUpperCase(WORDS_FR[index % WORDS_FR.length])
  }

  if(getLanguage() === Language.SPANISH){
    return localeAwareUpperCase(WORDS_ES[index % WORDS_ES.length])
  }

  if(getLanguage() === Language.GERMAN){
    return localeAwareUpperCase(WORDS_DE[index % WORDS_DE.length])
  }

  return localeAwareUpperCase(WORDS[index % WORDS.length])
}

export const getSolutions = (index: number) => {
  // if(isCustom() && getStoredCustomSolution() !== ''){
  //   return getStoredCustomSolution()!
  // }

  return getWordsOfDay(index)
}

export const solutionsMatch = (oldSolutions: string[], newSolutions: string[]) => {
  if(oldSolutions === undefined || oldSolutions.length !== newSolutions.length){
    return false
  }

  for(let word of oldSolutions){
    if(!newSolutions.includes(word)){
      return false
    }
  }

  for(let word of newSolutions){
    if(!oldSolutions.includes(word)){
      return false
    }
  }

  return true
}

export function getNewGameDate(isUnlimitedMode: boolean): Date {
  if(isUnlimitedMode){
    return startOfDay(new Date(Math.floor((Math.random() * 2) * Date.now())));
  } 
  else if(getIsSpeedy()){
    if(isSpeedyGameDone()){
      return addDays(getToday(), 10 * (getCounter() - 1) * 7)
    }

    return addDays(getToday(), 10 * getCounter() * 7)
  }
  
  return getToday()
}

export function move(move: Move, index: number, guesses: string[]) {
  if (move === 'down') {
    moveDown(index, guesses)
  } else if (move === 'up') {
    moveUp(index, guesses)
  } else if (move === 'left') {
    moveLeft(index, guesses)
  } else if (move === 'right') {
    moveRight(index, guesses)
  } 
}

export function moveDown(index: number, guesses: string[]) {
  var newLetter = guesses[0].charAt(index)

  for(let i = 0; i < guesses.length - 1; i++) {
    var newWord = replaceChatAt(newLetter, guesses[i + 1], index)
    newLetter = guesses[i + 1].charAt(index)
    guesses[i + 1] = newWord
  }

  guesses[0] = replaceChatAt(newLetter, guesses[0], index)
}

export function moveUp(index: number, guesses: string[]) {
  var newLetter = guesses[0].charAt(index)

  for(let i = guesses.length - 1; i > 0; i--) {
    var newWord = replaceChatAt(newLetter, guesses[i], index)
    newLetter = guesses[i].charAt(index)
    guesses[i] = newWord
  }

  guesses[0] = replaceChatAt(newLetter, guesses[0], index)
}

function replaceChatAt(newChar: string, word: string, index: number): string {
  return word.substring(0, index) + newChar + word.substring(index + 1);
}

export function moveLeft(index: number, guesses: string[]) {
  guesses[index] = guesses[index].substring(1, guesses[index].length) + guesses[index].charAt(0)
}

export function moveRight(index: number, guesses: string[]) {
  guesses[index] = guesses[index].charAt(guesses[index].length - 1) + guesses[index].substring(0, guesses[index].length - 1)
}