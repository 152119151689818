import { CompletedRow } from './CompletedRow'
import { CharStatus, Move } from '../../lib/statuses'
import { Cell } from './Cell'

type Props = {
  guesses: string[]
  revealing: Move
  statuses: Map<number, CharStatus[]>
  handleMoveDown?: Function
  handleMoveUp?: Function
  handleMoveRight?: Function
  handleMoveLeft?: Function
  revealIndex?: number
}

export const Grid = ({
  guesses,
  revealing,
  statuses,
  handleMoveDown,
  handleMoveUp,
  handleMoveLeft,
  handleMoveRight,
  revealIndex
}: Props) => {
  const topArrows = ['⇧','⇧','⇧','⇧','⇧']
  const bottomArrows = ['⇩','⇩','⇩','⇩','⇩']
  return (
    <div id="#toc-container">
      <div className="flex justify-center text-center my-1">
          {topArrows.map((title, i) => (
            <Cell
              key={i + 5}
              columnIndex={i}
              revealIndex={revealIndex}
              value={title}
              status={'absent'}
              revealing={revealing}
              handleMoveUp={handleMoveUp}
            />
        ))}
      </div>
      
      {
        guesses.map((guess, i) => (
          <CompletedRow
            key={i}
            rowIndex={i}
            revealIndex={revealIndex}
            guess={guess}
            revealing={revealing}
            statuses={statuses!}
            handleMoveLeft={handleMoveLeft}
            handleMoveRight={handleMoveRight}
          />
        ))
      }

      <div className="flex justify-center text-center">
        {bottomArrows.map((title, i) => (
          <Cell
            key={i + 105}
            columnIndex={i}
            revealIndex={revealIndex}
            value={title}
            status={'absent'}
            revealing={revealing}
            handleMoveDown={handleMoveDown}
          />
        ))}
      </div>
  </div>
  )
}
