export enum BOT_NAME {
    ENIGMA = 'ENIGMA',
    LUCAS = 'LUCAS',
    CALEB = 'CALEB',
    LILY = 'LILY',
    TOBY = 'TOBY',
    SCALI = 'SCALI',
    SVEN = 'SVEN',
    FAY = 'FAY',
    FAY_FAY = 'FAY_FAY',
    SPYFALL = 'SPYFALL',
    PATRICK = 'PATRICK',
    TASH = 'TASH',
    DUARTE = 'DUARTE',
    DINIS = 'DINIS',
    KENNY = 'KENNY',
    SAVANNAH = 'SAVANNAH',
    MICHAEL = 'MICHAEL',
    CAM = 'CAM',
    BRYN = 'BRYN'
}

export enum FIELD {
    LEVEL = 'LEVEL',
    BOT_NAME = 'BOT_NAME',
    COUNTRY = 'COUNTRY',
    STARTER = 'STARTER'
}

export const BOTS_INFO:{[key in BOT_NAME]:{[key in FIELD]:any}} = {
    [BOT_NAME.ENIGMA]: {
        [FIELD.LEVEL]: 10,
        [FIELD.BOT_NAME]: 'Spotle Bot',
        [FIELD.COUNTRY]: 'PT',
        [FIELD.STARTER]: undefined
    },
    [BOT_NAME.KENNY]: {
        [FIELD.LEVEL]: 9,
        [FIELD.BOT_NAME]: 'Bot Kenny',
        [FIELD.COUNTRY]: 'US',
        [FIELD.STARTER]: 'GAMER'

    },
    [BOT_NAME.SCALI]: {
        [FIELD.LEVEL]: 9,
        [FIELD.BOT_NAME]: 'Bot Scali',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'BAYOU'
    },
    [BOT_NAME.SAVANNAH]: {
        [FIELD.LEVEL]: 8,
        [FIELD.BOT_NAME]: 'Bot Savannah',
        [FIELD.COUNTRY]: 'US',
        [FIELD.STARTER]: 'AMPLE'
    },
    [BOT_NAME.MICHAEL]: {
        [FIELD.LEVEL]: 8,
        [FIELD.BOT_NAME]: 'Bot Michael',
        [FIELD.COUNTRY]: 'US',
        [FIELD.STARTER]: 'LUNAR'
    },
    [BOT_NAME.BRYN]: {
        [FIELD.LEVEL]: 8,
        [FIELD.BOT_NAME]: 'Bot Bryn',
        [FIELD.COUNTRY]: 'US',
        [FIELD.STARTER]: 'BEACH'
    },
    [BOT_NAME.SVEN]: {
        [FIELD.LEVEL]: 7,
        [FIELD.BOT_NAME]: 'Bot Sven',
        [FIELD.COUNTRY]: 'PT',
        [FIELD.STARTER]: 'FLOUR'
    },
    [BOT_NAME.FAY]: {
        [FIELD.LEVEL]: 7,
        [FIELD.BOT_NAME]: 'Bot Fay Fay',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'WHALE'
    },
    [BOT_NAME.LUCAS]: {
        [FIELD.LEVEL]: 6,
        [FIELD.BOT_NAME]: 'Bot Lucas',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'LUCAS'
    },
    [BOT_NAME.CALEB]: {
        [FIELD.LEVEL]: 6,
        [FIELD.BOT_NAME]: 'Bot Caleb',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'CALEB'
    },
    [BOT_NAME.PATRICK]: {
        [FIELD.LEVEL]: 5,
        [FIELD.BOT_NAME]: 'Bot Patrick',
        [FIELD.COUNTRY]: 'RO',
        [FIELD.STARTER]: 'EARTH'
    },
    [BOT_NAME.TASH]: {
        [FIELD.LEVEL]: 5,
        [FIELD.BOT_NAME]: 'Bot Tash',
        [FIELD.COUNTRY]: 'UK',
        [FIELD.STARTER]: 'MIAOW'
    },
    [BOT_NAME.CAM]: {
        [FIELD.LEVEL]: 4,
        [FIELD.BOT_NAME]: 'Bot Cam',
        [FIELD.COUNTRY]: 'US',
        [FIELD.STARTER]: undefined
    },
    [BOT_NAME.FAY_FAY]: {
        [FIELD.LEVEL]: 4,
        [FIELD.BOT_NAME]: 'Bot Fay Fay Fay Fay Fay Fay Fay Fay Fay Fay Fay Fay',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'WHELP'
    },
    [BOT_NAME.LILY]: {
        [FIELD.LEVEL]: 3,
        [FIELD.BOT_NAME]: 'Bot Lily',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'SHARK'
    },
    [BOT_NAME.TOBY]: {
        [FIELD.LEVEL]: 3,
        [FIELD.BOT_NAME]: 'Bot Toby',
        [FIELD.COUNTRY]: 'CA',
        [FIELD.STARTER]: 'CRAFT'
    },
    [BOT_NAME.DUARTE]: {
        [FIELD.LEVEL]: 2,
        [FIELD.BOT_NAME]: 'Bot Duarte',
        [FIELD.COUNTRY]: 'PT',
        [FIELD.STARTER]: undefined
    },
    [BOT_NAME.DINIS]: {
        [FIELD.LEVEL]: 2,
        [FIELD.BOT_NAME]: 'Bot Dinis',
        [FIELD.COUNTRY]: 'PT',
        [FIELD.STARTER]: undefined
    },
    [BOT_NAME.SPYFALL]: {
        [FIELD.LEVEL]: 1,
        [FIELD.BOT_NAME]: 'Bot Spyfall',
        [FIELD.COUNTRY]: 'US',
        [FIELD.STARTER]: 'ZIZIT'
    },
}

