import classnames from 'classnames'

import { REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode} from '../../lib/localStorage'
import { CharStatus, Move } from '../../lib/statuses'

type Props = {
  rowIndex?: number
  columnIndex?: number
  value?: string
  status?: CharStatus
  revealing: Move
  handleMoveDown?: Function
  handleMoveUp?: Function
  handleMoveLeft?: Function
  handleMoveRight?: Function
  revealIndex?: number
}

const calculateAnimationDelay = (
  revealing: Move, 
  rowIndex: number | undefined,
  columnIndex: number | undefined,
  revealIndex: number | undefined
):number => {
  if(revealIndex === undefined){
    return 0
  }
  
  if(revealing === 'down' && columnIndex === revealIndex && rowIndex !== undefined) {
    return rowIndex * REVEAL_TIME_MS
  }
  else if(revealing === 'up' && columnIndex === revealIndex && rowIndex !== undefined) {
    return (4 - rowIndex) * REVEAL_TIME_MS
  }
  else if(revealing === 'right' && rowIndex === revealIndex && columnIndex !== undefined) {
    return columnIndex * REVEAL_TIME_MS
  }
  else if(revealing === 'left' && rowIndex === revealIndex && columnIndex !== undefined) {
    return (4 - columnIndex) * REVEAL_TIME_MS
  }

  return 0
}

export const Cell = ({
  rowIndex, 
  columnIndex,
  value,
  status,
  revealing,
  handleMoveDown,
  handleMoveUp,
  handleMoveLeft,
  handleMoveRight,
  revealIndex
}: Props) => {
  const animationDelay = `${calculateAnimationDelay(revealing, rowIndex, columnIndex, revealIndex)}ms`
  const shouldReveal = animationDelay !== '0ms'
  const letterAnimation = animationDelay !== '0ms'
  const isHighContrast = getStoredIsHighContrastMode()

  var defaultClassName = 'xxshort:w-11 xxshort:h-11 short:text-xl short:w-12 short:h-12 h-14 w-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white'

  const classes = classnames(
    defaultClassName ,
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !status,
      'border-black dark:border-slate-100': value && !status,
      'absent shadowed bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'absent',
      'absent shadowed bg-black dark:bg-black text-white border-black dark:border-black':
        status === 'hidden',
      'correct shadowed bg-orange-500 text-white border-orange-500':
        status === 'correct' && isHighContrast,
      'present shadowed bg-cyan-500 text-white border-cyan-500':
        status === 'present' && isHighContrast,
      'correct shadowed bg-green-500 text-white border-green-500':
        status === 'correct' && !isHighContrast,
      'present shadowed bg-yellow-500 text-white border-yellow-500':
        status === 'present' && !isHighContrast,
      'cell-fill-animation': true,
      'cell-reveal': shouldReveal
    }
  )

  return (
    <div className={classes} style={{ animationDelay }} onClick={() => 
      { 
        if(value === '⇩' && handleMoveDown !== undefined) {
          handleMoveDown(columnIndex!)
        } else if (value === '⇧'  && handleMoveUp !== undefined) {
          handleMoveUp(columnIndex!)
        } else if (value === '⇨' && handleMoveRight !== undefined) {
          handleMoveRight(rowIndex!)
        } else if (value === '⇦' && handleMoveLeft !== undefined) {
          handleMoveLeft(rowIndex!)
        }
      }
    }>
      <div className={classnames({'letter-container': letterAnimation})} style={{ animationDelay }}>
        {value}
      </div>
    </div>
  )
}
