import { getIndex, unicodeSplit } from './words'
import { RANDOM } from '../constants/random'
import { getMove } from './helper'

export type CharStatus = 'absent' | 'present' | 'correct' | 'hidden' | 'empty'

export type Move = 'down' | 'up' | 'left' | 'right' | 'diagonal' | 'empty'

export const getSpecialBackground = (today: Date): string => {
  //kenny
  if(today.getMonth() === 10 && today.getDate() === 17){
    return 'https://media1.giphy.com/media/epd82n6GN6vMzzRygj/giphy.gif'
  }
  
  //christmas
  if(today.getMonth() === 11 && today.getDate() === 25){
    return 'https://media3.giphy.com/media/eoi0x4UDYfwhC9ozX7/giphy.gif'
  } 

  return ""
}

export function getInitialMoves(today: Date): Move[] {
  const randomNumber = RANDOM[getIndex(today) % RANDOM.length]
  var moves: Move[] = []

  var numOfMoves = 4
  if (today.getDay() === 0) {
    numOfMoves = 8
  } else if (today.getDay() === 6) {
    numOfMoves = 7
  } else if (today.getDay() === 4 || today.getDay() === 5) {
    numOfMoves = 6
  } else if (today.getDay() === 2 || today.getDay() === 3) {
    numOfMoves = 5
  } 

  for (let i = 0; i < randomNumber.length && i < numOfMoves; i++) {
    moves.push(getMove(randomNumber.charAt(i)))
  }

  return moves
}

export function getInitialMovesIndexes(today: Date): number[] {
  const randomNumber = RANDOM[getIndex(today) % RANDOM.length]
  var moves: number[] = []

  var numOfMoves = 4
  if (today.getDay() === 0) {
    numOfMoves = 8
  } else if (today.getDay() === 6) {
    numOfMoves = 7
  } else if (today.getDay() === 4 || today.getDay() === 5) {
    numOfMoves = 6
  } else if (today.getDay() === 2 || today.getDay() === 3) {
    numOfMoves = 5
  } 

  for (let i = 0; i < randomNumber.length && i < numOfMoves; i++) {
    moves.push(+randomNumber.charAt(i) % 5)
  }

  return moves
}

export const getGuessStatuses = (
  solutions: string[],
  guesses: string[],
  statuses: Map<number, CharStatus[]>
): Map<number, CharStatus[]> => {
  for(let index = 0; index < solutions.length; index++){
    const solution = solutions[index]
    const splitSolution = unicodeSplit(solution)
    const splitGuess = unicodeSplit(guesses[index])
    const solutionCharsTaken = splitSolution.map((_) => false)

    statuses.set(index, new Array(solution.length))

    // handle all correct cases first
    splitGuess.forEach((letter, i) => {
      if (letter === splitSolution[i]) {
        statuses.get(index)![i] = 'correct'
        solutionCharsTaken[i] = true
        return
      }
    })

    splitGuess.forEach((letter, i) => {
      if (statuses.get(index)![i] === 'correct') return

      if (!splitSolution.includes(letter)) {
        // handles the absent case
        statuses.get(index)![i] = 'absent'
        return
      }

      // now we are left with "present"s
      const indexOfPresentChar = splitSolution.findIndex(
        (x, index) => x === letter && !solutionCharsTaken[index]
      )

      if (indexOfPresentChar > -1) {
        statuses.get(index)![i] = 'present'
        solutionCharsTaken[indexOfPresentChar] = true
      } else {
        statuses.get(index)![i] = 'absent'
      }
    })
  }

  return statuses
}

export const wonGame = (statuses: Map<number, CharStatus[]>): boolean => {
  if(statuses === undefined || statuses.size === 0){
    return false
  }

  var won = true
  statuses.forEach((values, _) =>{
    for(let value of values) {
      if(value !== 'correct'){
        won = false
      }
    }
  })

  return won
}
