import { PlayIcon, ShareIcon } from '@heroicons/react/outline'
import Countdown from 'react-countdown'
import discordIcon from './discord.svg'

import {
  ENABLE_MIGRATE_STATS,
} from '../../constants/settings'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { MigrationIntro } from '../stats/MigrationIntro'
import { StatBar } from '../stats/StatBar'
import { BaseModal } from './BaseModal'
import { CharStatus } from '../../lib/statuses'
import { getIsSpeedy, getIsUnlimitedMode } from '../../App'
import { getRandomPath, getRandomPathTranslation, getTranslation } from '../../context/messages'
import { isSpeedyGameDone } from '../../lib/speedy'
import { TranslationKey } from '../../constants/strings'
import { getLanguagePageUrl } from '../../route/GameRoute'
import { getToday } from '../../lib/dateutils'
import { addDays } from 'date-fns'


type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  handleMigrateStatsButton: () => void
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  statuses: Map<number, CharStatus[]>
  today: Date
  onResetGame: (softReset?: boolean) => void
  setIsResetModalOpen: (modal: boolean) => void
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameWon,
  handleShareToClipboard,
  handleShareFailure,
  handleMigrateStatsButton,
  isHighContrastMode,
  statuses,
  onResetGame,
  setIsResetModalOpen
}: Props) => {
  const showPlayAgainButton = getIsUnlimitedMode()

  return (
    <BaseModal
      title={TranslationKey.STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <hr className="mt-3 border-gray-500" />
      {!getIsSpeedy() && (
        <div>
          <h4 className="mt-2 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            {getTranslation(TranslationKey.GUESS_DISTRIBUTION_TEXT)}
          </h4>
        </div>
      )}
      {(isGameWon) && (
        <div>
          <hr className="mt-3 border-gray-500" />
          <div className="mt-3 columns-2 items-center items-stretch justify-center text-center dark:text-white">
            {!getIsUnlimitedMode() && (
              <div className="inline-block w-full text-left">
                <div>
                  <h5>{getTranslation(TranslationKey.NEW_WORD_TEXT)}</h5>
                  <Countdown
                    className="text-lg font-medium text-gray-900 dark:text-gray-100"
                    date={addDays(getToday(), 1)}
                    daysInHours={true}
                  />
                </div>
              </div>
            )}
            <div>
              <button
                type="button"
                className="mt-1 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  shareStatus(
                    handleShareToClipboard,
                    handleShareFailure
                  )
                }}
              >
                <ShareIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getTranslation(TranslationKey.SHARE_TEXT)}
              </button>
            </div>
          </div>
          <div className="items-center items-stretch justify-center text-center dark:text-white">
          {(!getIsSpeedy()) && (
            <div>
              <hr className="mt-3 border-gray-500" />
              <button
                type="button"
                className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  window.location.href=getLanguagePageUrl(getRandomPath())
                }}
              >
                <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {getTranslation(TranslationKey.GAMEMODE_OF_THE_DAY) + getRandomPathTranslation()}
              </button>              
            </div>
          )}
          </div>
        </div>
      )}
      {(ENABLE_MIGRATE_STATS && (!getIsSpeedy() || isSpeedyGameDone())) && (
        <div>
          <hr className="mt-3 border-gray-500" />
          <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
        </div>
      )}    
      {(showPlayAgainButton) && (
        <div>
          <hr className="mt-3 border-gray-500" />
          <div className="mt-3 items-center items-stretch justify-center text-center dark:text-white">
            <button
              type="button"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={() => {
                onResetGame()
              }}
            >
              <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              {getTranslation(TranslationKey.PLAY_AGAIN)}
            </button>
          </div>
        </div>
      )}
      <div>
        <hr className="mt-3 border-gray-500" />
        <p className="w-16 h-16">
          <a href="https://discord.gg/uvFg7ggW4B" target="_blank" rel="noopener noreferrer">
            <img src={discordIcon} alt="kennyhallerDC" />
          </a>{' '}
        </p>        
      </div>
    </BaseModal>
  )
}
