import { CharStatus } from '../../lib/statuses'
import { getTranslation, getTranslationWithIndex } from '../../context/messages'
import { Cell } from '../grid/Cell'
import { Grid } from '../grid/Grid'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './settings/SettingsToggle'
import { TranslationKey } from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isNormalStatuses: boolean
  handleIsNormalStatuses: Function
}

export const InfoModal = ({ isOpen, handleClose, isNormalStatuses, handleIsNormalStatuses }: Props) => {
  const guesses = ['LIBEL', 'BOOST', 'WHALE', 'FLACK', 'FLOAT', 'FLOUR']

  const spotleStatuses: Map<number, CharStatus[]> = new Map<number, CharStatus[]>()
  spotleStatuses.set(0, ['hidden','absent','absent', 'hidden', 'absent'])
  spotleStatuses.set(1, ['hidden','absent','hidden', 'hidden', 'hidden'])
  spotleStatuses.set(2, ['absent','absent','hidden', 'present', 'absent'])
  spotleStatuses.set(3, ['correct','hidden','hidden', 'absent', 'absent'])
  spotleStatuses.set(4, ['hidden','correct','correct', 'hidden', 'hidden'])
  spotleStatuses.set(5, ['correct','correct','correct', 'correct', 'correct'])

  const normalStatuses: Map<number, CharStatus[]> = new Map<number, CharStatus[]>()
  normalStatuses.set(0, ['present','absent','absent', 'absent', 'absent'])
  normalStatuses.set(1, ['absent','absent','correct', 'absent', 'absent'])
  normalStatuses.set(2, ['absent','absent','absent', 'present', 'absent'])
  normalStatuses.set(3, ['correct','correct','absent', 'absent', 'absent'])
  normalStatuses.set(4, ['correct','correct','correct', 'absent', 'absent'])
  normalStatuses.set(5, ['correct','correct','correct', 'correct', 'correct'])

  var statuses: Map<number, CharStatus[]> = spotleStatuses

  if(isNormalStatuses){
    statuses = normalStatuses
  }

  return (
    <BaseModal title={TranslationKey.HOW_TO_PLAY} isOpen={isOpen} handleClose={handleClose}>
      <p className="text-md text-gray-500 dark:text-gray-300 mb-2 mt-4">
        {getTranslation(TranslationKey.HOW_TO_PLAY_INTRO)}
      </p>

      <div className="flex grow flex-col justify-center short:pb-2">
        <Grid
          revealing={'empty'} 
          guesses={guesses}
          statuses={statuses!}
        /> 
      </div>

      <div className="flex flex-col divide-y">
        <SettingsToggle
          settingName={TranslationKey.ENIGMA}
          flag={isNormalStatuses}
          handleFlag={handleIsNormalStatuses}
        />
      <div>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD, 0)} status="correct"/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD, 1)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD, 2)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD, 3)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD, 4)}/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {getTranslation(TranslationKey.HOW_TO_PLAY_INTRO_2)}
      </p>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_2, 0)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_2, 1)} status="present"/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_2, 2)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_2, 3)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_2, 4)}/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      {getTranslation(TranslationKey.HOW_TO_PLAY_INTRO_3)}
      </p>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_3, 0)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_3, 1)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_3, 2)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_3, 3)} status="absent"/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_3, 4)}/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      {getTranslation(TranslationKey.HOW_TO_PLAY_INTRO_4)}
      </p>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_4, 0)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_4, 1)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_4, 2)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_4, 3)}/>
        <Cell revealing={'empty'} value={getTranslationWithIndex(TranslationKey.HOW_TO_PLAY_WORD_4, 4)} status="hidden"/>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      {getTranslation(TranslationKey.HOW_TO_PLAY_INTRO_5)}
      </p>
      </div>
      </div>
    </BaseModal>
  )
}
