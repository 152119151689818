import './App.css'

import { PlayIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'

import { AlertContainer } from './components/alerts/AlertContainer'
import { recordGaEvent } from './components/alerts/Analytics'
import { Grid } from './components/grid/Grid'
import { AboutUsModal } from './components/modals/AboutUsModal'
import { AnnouncementsModal } from './components/modals/AnnouncementsModal'
import { CustomGameModal } from './components/modals/CustomGameModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { NewGameModeModal } from './components/modals/NewGameModeModal'
import { ResetModal } from './components/modals/ResetModal'
import { StatsModal } from './components/modals/StatsModal'
import { GamesModal, MODES } from './components/modals/settings/GamesModal'
import { LanguageModal } from './components/modals/settings/LanguageModal'
import { SettingsModal } from './components/modals/settings/SettingsModal'
import { ThemeSettingsModal } from './components/modals/settings/ThemeSettingsModal'
import { Footbar } from './components/navbar/Footbar'
import { Navbar } from './components/navbar/Navbar'
import { Language } from './constants/language'
import {
  APRIL_FOOLS_DATE,
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import { TranslationKey } from './constants/strings'
import { useAlert } from './context/AlertContext'
import { getPageTitle, getTranslation, getWinMessage } from './context/messages'
import { isInAppBrowser } from './lib/browser'
import { equalArray } from './lib/helper'
import {
  getCounter,
  getHasSeenAnnouncements,
  getHasSeenNewGameModeInfo,
  getLanguage,
  getStoredBotChallengeMode,
  getStoredDarkMode,
  getStoredGameDate,
  getStoredIsHighContrastMode,
  getStoredStatuses,
  incCounter,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setCounter,
  setHasSeenAnnouncements,
  setHasSeenNewGameModeInfo,
  setLanguage,
  setStoredCustomInfo,
  setStoredDarkMode,
  setStoredGameDate,
  setStoredGuessDate,
  setStoredIsHighContrastMode,
  setStoredRestart,
  setStoredStatuses,
  setTitle,
} from './lib/localStorage'
import { isSpeedyGameDone } from './lib/speedy'
import { loadStats } from './lib/stats'
import {
  CharStatus,
  Move,
  getGuessStatuses,
  getInitialMoves,
  getInitialMovesIndexes,
  getSpecialBackground,
  wonGame,
} from './lib/statuses'
import {
  getIndex,
  getNewGameDate,
  getSolutions,
  move,
  moveDown,
  moveLeft,
  moveRight,
  moveUp,
} from './lib/words'

var isUnlimitedMode = false
export function getIsUnlimitedMode(): boolean {
  return isUnlimitedMode
}

var horsle = false
export function getIsHorsle(): boolean {
  return horsle
}

var speedy = false
export function getIsSpeedy(): boolean {
  return speedy
}

var custom = false
export function isCustom(): boolean {
  return custom
}

function App(props: {
  isUnlimitedMode: boolean
  language: Language
  horsle: boolean
  taytay: boolean
  custom: boolean
  speedy: boolean
  path: string
}) {
  isUnlimitedMode = props.isUnlimitedMode
  horsle = props.horsle
  custom = props.custom
  speedy = props.speedy

  //here just in case a player accesses the page directly
  //without using the settings to switch language
  if (typeof props.language !== 'undefined') {
    setLanguage(props.language)
  }

  setTitle(getPageTitle(props.path))

  if (isCustom()) {
    setStoredCustomInfo(window.location.href)
  }

  const {
    showError: showErrorAlert,
    showSuccess: showSuccessAlert,
    showTie: showTieAlert,
  } = useAlert()
  const [isGameWon, setIsGameWon] = useState(false)
  const [isFireworks, setIsFireworks] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isNormalStatuses, setIsNormalStatuses] = useState(true)
  const handleIsNormalStatuses = (newNormal: boolean) => {
    setIsNormalStatuses(newNormal)
  }
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const [isGamesModalOpen, setIsGamesModalOpen] = useState(false)
  const [isThemeSettingsModalOpen, setIsThemeSettingsModalOpen] =
    useState(false)
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [isNewGameModeInfoModalOpen, setIsNewGameModeInfoModalOpen] =
    useState(false)
  const [isAnnouncementsModalOpen, setIsAnnouncementsModalOpen] =
    useState(false)
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(getStoredDarkMode())
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [solutionIndex, setIndex] = useState(getIndex(getStoredGameDate()))
  const [solutions, setSolutions] = useState(getSolutions(solutionIndex))

  const [isCustomGameModalOpen, setIsCustomGameModalOpen] = useState(
    isCustom() && window.location.href.endsWith('/custom')
  )

  const [revealing, setRevealing] = useState<Move>('empty')
  const [revealIndex, setRevealIndex] = useState(-1)

  const [stats, setStats] = useState(() => loadStats())

  const [pageNumber, setPageNumber] = useState(0)
  const [pageType, setPageType] = useState(MODES.NONE)

  const [statuses, setStatuses] = useState(
    getStoredStatuses().size === 0
      ? new Map<number, CharStatus[]>()
      : getStoredStatuses()
  )

  const initialAprilFoolsMessage = (date: Date) => {
    if (
      date.getDate() !== APRIL_FOOLS_DATE.getDate() ||
      date.getMonth() !== APRIL_FOOLS_DATE.getMonth() ||
      getIsUnlimitedMode()
    ) {
      return
    }
  }

  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    //TODO randommise guesses using the solutions
    if (loaded === null || !equalArray(loaded?.solutions, solutions)) {
      initialAprilFoolsMessage(getStoredGameDate())

      setStoredRestart(false)

      return resetGuesses(solutions, true)
    }

    return loaded.guesses
  })

  function resetGuesses(solutions: string[], initialSetup: boolean): string[] {
    var newGuesses: string[] = []
    for (let word of solutions) {
      newGuesses.push(word)
    }

    const moves = getInitialMoves(getStoredGameDate())
    const indexes = getInitialMovesIndexes(getStoredGameDate())

    for (let i = 0; i < moves.length; i++) {
      move(moves[i], indexes[i], newGuesses)
    }

    setStoredStatuses(
      getGuessStatuses(solutions, newGuesses, new Map<number, CharStatus[]>())
    )

    if (!initialSetup) {
      setGuesses(newGuesses)
      setStoredRestart(true)
    }

    setStatuses(getStoredStatuses())
    setCounter(0)

    return newGuesses
  }

  const handleMove = (index: number, newRevealing: Move) => {
    setGuesses(guesses)
    setStoredStatuses(getGuessStatuses(solutions, guesses, statuses))
    setStatuses(getStoredStatuses())
    saveGameStateToLocalStorage({ guesses, solutions })

    setRevealing(newRevealing)
    setRevealIndex(index)
    incCounter()
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setRevealing('empty')
      setRevealIndex(-1)
    }, REVEAL_TIME_MS * guesses[0].length)
  }

  const handleMoveDown = (index: number) => {
    if (!isGameWon) {
      moveDown(index, guesses)
      handleMove(index, 'down')
      recordGaEvent('down')
    }
  }

  const handleMoveUp = (index: number) => {
    if (!isGameWon) {
      moveUp(index, guesses)
      handleMove(index, 'up')
      recordGaEvent('up')
    }
  }

  const handleMoveLeft = (index: number) => {
    if (!isGameWon) {
      moveLeft(index, guesses)
      handleMove(index, 'left')
      recordGaEvent('left')
    }
  }

  const handleMoveRight = (index: number) => {
    if (!isGameWon) {
      moveRight(index, guesses)
      handleMove(index, 'right')
      recordGaEvent('right')
    }
  }

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage() && !isUnlimitedMode && !isCustom()) {
      recordGaEvent('new_player')

      setTimeout(() => {
        setIsInfoModalOpen(true)
        setHasSeenNewGameModeInfo(true)
        setHasSeenAnnouncements(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    if (loadGameStateFromLocalStorage() && !isUnlimitedMode && !isCustom()) {
      if (!getHasSeenAnnouncements()) {
        setTimeout(() => {
          setHasSeenAnnouncements(true)
          setIsAnnouncementsModalOpen(true)
          setHasSeenNewGameModeInfo(true)
        }, WELCOME_INFO_MODAL_MS * 2)
      } else if (!getHasSeenNewGameModeInfo()) {
        setTimeout(() => {
          setHasSeenNewGameModeInfo(true)
          setIsNewGameModeInfoModalOpen(true)
          setHasSeenAnnouncements(true)
        }, WELCOME_INFO_MODAL_MS * 2)
      }
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(
        getTranslation(TranslationKey.DISCOURAGE_INAPP_BROWSER_TEXT),
        {
          persist: false,
          durationMs: 7000,
        }
      )
  }, [showErrorAlert])

  useEffect(() => {
    setIsGameWon(wonGame(statuses))
  }, [statuses])

  useEffect(() => {
    setStoredStatuses(getGuessStatuses(solutions, guesses, getStoredStatuses()))
    setStatuses(getStoredStatuses())
  }, [guesses, solutions])

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(
        getTranslation(TranslationKey.DISCOURAGE_INAPP_BROWSER_TEXT),
        {
          persist: false,
          durationMs: 7000,
        }
      )
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    setStoredDarkMode(isDark)
  }

  const handleCustomSolution = (customSolution: string) => {
    setStoredCustomInfo(customSolution)
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solutions })

    setStoredStatuses(
      getGuessStatuses(solutions, guesses, new Map<number, CharStatus[]>())
    )
    setStatuses(getStoredStatuses())
  }, [guesses, solutions])

  useEffect(() => {
    if (!getIsSpeedy()) {
      var delayMs = REVEAL_TIME_MS * solutions.length

      if (isGameWon) {
        setTimeout(() => {
          setIsFireworks(true)
          setStoredGuessDate(undefined)
        }, delayMs)

        showSuccessAlert(getWinMessage(getStoredGameDate()), {
          delayMs,
          onClose: () => {
            setIsStatsModalOpen(true)
            setStoredGuessDate(undefined)
          },
        })
      }
    }
  }, [
    isGameWon,
    isGameLost,
    showSuccessAlert,
    solutions,
    showErrorAlert,
    showTieAlert,
  ])

  const onResetGame = (override?: boolean) => {
    if (
      ((getIsSpeedy() && !isSpeedyGameDone()) ||
        isGameWon ||
        isGameLost ||
        override) &&
      getIsUnlimitedMode()
    ) {
      setGuesses([])

      setStoredGameDate(getNewGameDate(getIsUnlimitedMode()))

      setIsFireworks(false)
      setIsGameWon(false)
      setIsGameLost(false)
      setIsStatsModalOpen(false)
      setIsInfoModalOpen(false)
      setIsLanguageModalOpen(false)
      setIsGamesModalOpen(false)
      setIsMigrateStatsModalOpen(false)
      setIndex(getIndex(getStoredGameDate()))
      setSolutions(getSolutions(getIndex(getStoredGameDate())))
      setStats(loadStats())
      setStoredStatuses(getGuessStatuses(solutions, guesses, statuses))
      setStatuses(getStoredStatuses())

      if (!getIsSpeedy() && !getStoredBotChallengeMode()) {
        showSuccessAlert(getTranslation(TranslationKey.NEW_GAME))
      }
    } else if (!getIsUnlimitedMode()) {
      const newSolutions = getSolutions(getIndex(getStoredGameDate()))

      const loaded = loadGameStateFromLocalStorage()
      if (loaded?.solutions !== newSolutions) {
        setIsGameWon(false)
        setIsGameLost(false)
        setGuesses([])
        setStoredGameDate(getNewGameDate(getIsUnlimitedMode()))
        setStoredStatuses(getGuessStatuses(solutions, guesses, statuses))
        setStatuses(getStoredStatuses())
      } else {
        setGuesses(loaded?.guesses)
        setStatuses(getStoredStatuses())

        //guesses were reset above
        setIsGameWon(wonGame(getStoredStatuses()))
      }
      setIsStatsModalOpen(false)
      setIsInfoModalOpen(false)
      setIsLanguageModalOpen(false)
      setIsGamesModalOpen(false)
      setIsMigrateStatsModalOpen(false)
      setIsFireworks(false)
      setIndex(getIndex(getStoredGameDate()))
      setSolutions(newSolutions)
      setStats(loadStats())

      if (!getIsSpeedy()) {
        showSuccessAlert(getTranslation(TranslationKey.NEW_GAME))
      }
    } else {
      showErrorAlert(getTranslation(TranslationKey.INCOMPLETE_GAME))
    }

    if (getIsHorsle()) {
      //recalcDefinitionAPI(solutions)
    }

    return
  }

  return (
    <div>
      <div id="ezoic-pub-ad-placeholder-101"> </div>
      <div
        className="flex h-full flex-col"
        style={
          isFireworks && !isUnlimitedMode
            ? {
                backgroundImage:
                  'url(' + getSpecialBackground(getStoredGameDate()) + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% -9%',
              } //horizontal / vertical
            : {}
        }
      >
        <div
          className="flex h-full flex-col"
          style={
            isFireworks && !isUnlimitedMode
              ? {
                  backgroundImage:
                    'url(https://media2.giphy.com/media/O5QlTr51qI5RrEu7Bn/giphy.gif)',
                }
              : {}
          }
        >
          <Navbar
            setIsInfoModalOpen={setIsInfoModalOpen}
            setIsLanguageModalOpen={setIsLanguageModalOpen}
            setIsGamesModalOpen={setIsGamesModalOpen}
            setIsStatsModalOpen={setIsStatsModalOpen}
            setIsSettingsModalOpen={setIsSettingsModalOpen}
          />
          <div id="ezoic-pub-ad-placeholder-118"> </div>
          <div id="ezoic-pub-ad-placeholder-111"> </div>
          <div id="ezoic-pub-ad-placeholder-110"> </div>
          {getIsHorsle() && (
            <p className="text-center text-xs italic dark:text-white"></p> //{getShortnedDefinitionText(solutions)}
          )}
          <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
            <div className="flex justify-center">{getCounter()}</div>
            <div className="flex grow flex-col justify-center pb-6 short:pb-2">
              <Grid
                guesses={guesses}
                revealing={revealing}
                statuses={statuses!}
                handleMoveDown={handleMoveDown}
                handleMoveUp={handleMoveUp}
                handleMoveLeft={handleMoveLeft}
                handleMoveRight={handleMoveRight}
                revealIndex={revealIndex}
              />
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                disabled={isGameWon}
                className="mt-1 inline-flex w-1/6 items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  resetGuesses(solutions, false)
                }}
              >
                <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getTranslation(TranslationKey.RESET)}
              </button>
            </div>
            <InfoModal
              isOpen={isInfoModalOpen}
              handleClose={() => setIsInfoModalOpen(false)}
              isNormalStatuses={isNormalStatuses}
              handleIsNormalStatuses={handleIsNormalStatuses}
            />
            <LanguageModal
              isOpen={isLanguageModalOpen}
              handleClose={() => setIsLanguageModalOpen(false)}
            />
            <GamesModal
              isOpen={isGamesModalOpen}
              handleClose={() => setIsGamesModalOpen(false)}
              pageNumber={pageNumber}
              handleDecPageNumber={() => {
                setPageNumber(pageNumber - 1)
              }}
              handleIncPageNumber={() => {
                setPageNumber(pageNumber + 1)
              }}
              pageType={pageType}
              handleBlankPageType={() => setPageType(MODES.NONE)}
              handleToDailyPageType={() => setPageType(MODES.DAILY)}
              handleToUnlimitedPageType={() => setPageType(MODES.UNLIMITED)}
              handleToMultiplayerPageType={() => setPageType(MODES.MULTIPLAYER)}
            />
            <ResetModal
              isOpen={isResetModalOpen}
              handleClose={() => setIsResetModalOpen(false)}
              setStats={setStats}
            />
            <ThemeSettingsModal
              isOpen={isThemeSettingsModalOpen}
              handleClose={() => setIsThemeSettingsModalOpen(false)}
              isDarkMode={isDarkMode}
              handleDarkMode={handleDarkMode}
              isHighContrastMode={isHighContrastMode}
              handleHighContrastMode={handleHighContrastMode}
            />
            <SettingsModal
              isOpen={isSettingsModalOpen}
              handleClose={() => setIsSettingsModalOpen(false)}
              setIsThemeSettingsModalOpen={setIsThemeSettingsModalOpen}
              setIsGamesModalOpen={setIsGamesModalOpen}
              setIsLanguageModalOpen={setIsLanguageModalOpen}
            />
            <AboutUsModal
              isOpen={isContactInfoModalOpen}
              handleClose={() => setIsContactInfoModalOpen(false)}
            />
            <NewGameModeModal
              isOpen={
                isNewGameModeInfoModalOpen &&
                !isInfoModalOpen &&
                !isAnnouncementsModalOpen
              }
              handleClose={() => setIsNewGameModeInfoModalOpen(false)}
            />
            <AnnouncementsModal
              isOpen={isAnnouncementsModalOpen && !isInfoModalOpen}
              handleClose={() => setIsAnnouncementsModalOpen(false)}
            />
            <StatsModal
              isOpen={isStatsModalOpen}
              handleClose={() => setIsStatsModalOpen(false)}
              guesses={guesses}
              gameStats={stats}
              isGameWon={isGameWon}
              handleShareToClipboard={() =>
                showSuccessAlert(
                  getTranslation(TranslationKey.GAME_COPIED_MESSAGE)
                )
              }
              handleShareFailure={() =>
                showErrorAlert(
                  getTranslation(TranslationKey.SHARE_FAILURE_TEXT),
                  {
                    durationMs: LONG_ALERT_TIME_MS,
                  }
                )
              }
              handleMigrateStatsButton={() => {
                setIsStatsModalOpen(false)
                setIsMigrateStatsModalOpen(true)
              }}
              isDarkMode={isDarkMode}
              isHighContrastMode={isHighContrastMode}
              numberOfGuessesMade={guesses.length}
              statuses={statuses}
              today={getStoredGameDate()}
              onResetGame={onResetGame}
              setIsResetModalOpen={setIsResetModalOpen}
            />
            <CustomGameModal
              isOpen={isCustomGameModalOpen}
              handleClose={() => setIsCustomGameModalOpen(false)}
              handleCustomSolution={handleCustomSolution}
              path={props.path}
              showSuccessAlert={showSuccessAlert}
            />
            <MigrateStatsModal
              isOpen={isMigrateStatsModalOpen}
              handleClose={() => setIsMigrateStatsModalOpen(false)}
            />
            <AlertContainer />
          </div>

          <Footbar
            setIsContactInfoModalOnly={setIsContactInfoModalOpen}
            showDefinition={
              (isGameWon || isGameLost) &&
              getLanguage() === Language.ENGLISH &&
              !getIsHorsle() &&
              !getIsSpeedy() &&
              !revealing
            }
            solutions={solutions}
          />
        </div>
      </div>
      <div id="ezoic-pub-ad-placeholder-102"> </div>
    </div>
  )
}

export default App
