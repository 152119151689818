import '../index.css'

import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route, Routes, useLocation } from 'react-router-dom'

import Imperialists from './Imperialists'
import NaughtyApp from './NaughtyApp'
import { cookiesPolicy, privacyPolicy, tosPolicy } from '../constants/policies'
import { TranslationKey } from '../constants/strings'
import { getPageTitle } from '../context/messages'
import { SvenTime } from '../lib/SvenTime'
import {
  isCountrySet,
  isRussia,
  setCountry,
} from '../lib/localStorage'
import { getCountry } from '../lib/timezones'
import Policy from '../policies/Policy'
import GameRoute from './GameRoute'

const RoutedApp = () => {
  ReactGA.initialize('G-FHDSTY7F07')

  if (!isCountrySet()) {
    setCountry(getCountry())
  }

  const location = useLocation()
  useEffect(() => {
    document.title = getPageTitle(location.pathname)
  }, [location])

  if (isRussia()) {
    return (
      <div>
        <Routes>
          <Route path="*" element={<Imperialists />} />
        </Routes>
      </div>
    )
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<GameRoute path="/"/>}/>
        <Route path="/english" element={<GameRoute path="/english"/>}/>
        <Route path="/portuguese" element={<GameRoute path="/portuguese"/>}/>
        <Route path="/portugues" element={<GameRoute path="/portugues"/>}/>
        <Route path="/french" element={<GameRoute path="/french"/>}/>
        <Route path="/francais" element={<GameRoute path="/francais"/>}/>
        <Route path="/spanish" element={<GameRoute path="/spanish"/>}/>
        <Route path="/espanol" element={<GameRoute path="/espanol"/>}/>
        <Route path="/german" element={<GameRoute path="/german"/>}/>
        <Route path="/deutsch" element={<GameRoute path="/deutsch"/>}/>

        <Route path="/unlimited" element={<GameRoute path="/unlimited"/>}/>
        <Route path="/english/unlimited" element={<GameRoute path="/english/unlimited"/>}/>
        <Route path="/portuguese/unlimited" element={<GameRoute path="/portuguese/unlimited"/>}/>
        <Route path="/portugues/unlimited" element={<GameRoute path="/portugues/unlimited"/>}/>
        <Route path="/french/unlimited" element={<GameRoute path="/french/unlimited"/>}/>
        <Route path="/francais/unlimited" element={<GameRoute path="/francais/unlimited"/>}/>
        <Route path="/spanish/unlimited" element={<GameRoute path="/spanish/unlimited"/>}/>
        <Route path="/espanol/unlimited" element={<GameRoute path="/espanol/unlimited"/>}/>
        <Route path="/german/unlimited" element={<GameRoute path="/german/unlimited"/>}/>
        <Route path="/deutsch/unlimited" element={<GameRoute path="/deutsch/unlimited"/>}/>

        <Route path="/dummies" element={<GameRoute path="/dummies"/>}/>

        <Route path="/swift" element={<GameRoute path="/swift"/>}/>
        <Route path="/swift/unlimited" element={<GameRoute path="/swift/unlimited"/>}/>

        <Route path="/custom/*" element={<GameRoute path="/custom/*"/>}/>
        <Route path="/english/custom/*" element={<GameRoute path="/english/custom/*"/>}/>
        <Route path="/portuguese/custom/*" element={<GameRoute path="/portuguese/custom/*"/>}/>
        <Route path="/portugues/custom/*" element={<GameRoute path="/portugues/custom/*"/>}/>
        <Route path="/french/custom/*" element={<GameRoute path="/french/custom/*"/>}/>
        <Route path="/francais/custom/*" element={<GameRoute path="/francais/custom/*"/>}/>
        <Route path="/spanish/custom/*" element={<GameRoute path="/spanish/custom/*"/>}/>
        <Route path="/espanol/custom/*" element={<GameRoute path="/espanol/custom/*"/>}/>
        <Route path="/german/custom/*" element={<GameRoute path="/german/custom/*"/>}/>
        <Route path="/deutsch/custom/*" element={<GameRoute path="/deutsch/custom/*"/>}/>

        <Route path="/tos"
          element={
            <Policy title={TranslationKey.TERMS_OF_SERVICE} policy={tosPolicy}/>
          }
        />
        <Route path="/r*ssia" element={<Imperialists />} />
        <Route path="/gamer" element={<NaughtyApp />} />
        <Route path="/privacypolicy"
          element={
            <Policy title={TranslationKey.PRIVACY_POLICY} policy={privacyPolicy}/>
          }
        />
        <Route path="/cookiespolicy"
          element={
            <Policy title={TranslationKey.COOKIES_POLICY} policy={cookiesPolicy}/>
          }
        />
        <Route path="/sventime" element={<SvenTime />} />
        <Route path="*" element={<NaughtyApp />} />
      </Routes>
    </div>
  )
}

export default RoutedApp
