import { BaseModal } from '../BaseModal'
import { getTranslation, getTranslationWithInfo} from '../../../context/messages'
import { GameModeSwitchModal } from './GameModeSwitchModal'
import { TranslationKey } from '../../../constants/strings'

export enum MODES {
  NONE = 'None',
  DAILY = 'Daily',
  UNLIMITED = 'Unlimited',
  MULTIPLAYER = 'Multiplayer'
}

type Props = {
  isOpen: boolean
  handleClose: () => void
  pageNumber: number
  handleIncPageNumber: () => void
  handleDecPageNumber: () => void
  pageType: string
  handleToDailyPageType: () => void
  handleToUnlimitedPageType: () => void
  handleToMultiplayerPageType: () => void
  handleBlankPageType: () => void
}

export const GamesModal = ({
  isOpen,
  handleClose,
  pageNumber,
  handleIncPageNumber,
  handleDecPageNumber,
  pageType,
  handleToDailyPageType,
  handleToUnlimitedPageType,
  handleToMultiplayerPageType,
  handleBlankPageType
}: Props) => {
  var daily = pageType === MODES.DAILY
  var unlimited = pageType === MODES.UNLIMITED
  var multiplayer = pageType === MODES.MULTIPLAYER
  
  var message = ''
  if(daily){
    message = getTranslationWithInfo(TranslationKey.CHOOSE_GAME_MODE, getTranslation(TranslationKey.DAILY_MODE))
  } else if (unlimited){
    message = getTranslationWithInfo(TranslationKey.CHOOSE_GAME_MODE, getTranslation(TranslationKey.UNLIMITED_MODE))
  } else if (multiplayer){
    message = getTranslationWithInfo(TranslationKey.CHOOSE_GAME_MODE, getTranslation(TranslationKey.MULTIPLAYER_MODE))
  }
  return (
    <BaseModal title={TranslationKey.GAME_MODES} isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
        {message}
      </p> 
      <br></br> 

      {!daily && !unlimited && !multiplayer && (
        <div key={0} className="mt-2 flex flex-col divide-y">
          {!daily && (
            <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
                handleToDailyPageType()
              }}
            >
              {getTranslation(TranslationKey.DAILY_MODES)}
            </button>
          )}
          {!unlimited && (
            <button
              type="button"
              className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={event => {
                handleToUnlimitedPageType()
              }}
            >
              {getTranslation(TranslationKey.UNLIMITED_MODES)}
            </button>
          )}
          {!multiplayer && (
            <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              handleToMultiplayerPageType()
            }}
          >
            {getTranslation(TranslationKey.MULTIPLAYER_MODE)}
          </button>
          )}
        </div>
      )}
      {daily && (
        <>
          <GameModeSwitchModal index={0} pageNumber={pageNumber} location={'/'} description={TranslationKey.DAILY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={1} pageNumber={pageNumber} location={'/lazy'} description={TranslationKey.LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={2} pageNumber={pageNumber} location={'/swift'} description={TranslationKey.SWIFTIE_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={3} pageNumber={pageNumber} location={'/speedy'} description={TranslationKey.SPEEDY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={4} pageNumber={pageNumber} location={'/speedy/lazy'} description={TranslationKey.SPEEDY_LAZY_MODE_DESCRIPTION}/>
        </>
      )}
      {unlimited && (
        <>
          <GameModeSwitchModal index={0} pageNumber={pageNumber} location={'/unlimited'} description={TranslationKey.UNLIMITED_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={1} pageNumber={pageNumber} location={'/speedy/lazy/unlimited'} description={TranslationKey.SPEEDY_LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={2} pageNumber={pageNumber} location={'/swift/unlimited'} description={TranslationKey.SWIFTIE_UNLIMITED_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={3} pageNumber={pageNumber} location={'/custom'} description={TranslationKey.CUSTOM_DESCRIPTION}/>
          <GameModeSwitchModal index={4} pageNumber={pageNumber} location={'/lazy/unlimited'} description={TranslationKey.LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={5} pageNumber={pageNumber} location={'/speedy/unlimited'} description={TranslationKey.SPEEDY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={6} pageNumber={pageNumber} location={'/impossible'} description={TranslationKey.IMPOSSIBLE_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={7} pageNumber={pageNumber} location={'/staircase'} description={TranslationKey.STAIRCASE_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={8} pageNumber={pageNumber} location={'/dummies'} description={TranslationKey.DUMMIES_MODE_DESCRIPTION}/>
        </>
      )}
      {multiplayer && (
        <div>
          <p className="text-center text-md font-bold text-red-500">
            CoSpotle is still in alpha. Bugs are expected.
          </p>
          <p className="mt-2 text-center text-xs italic font-bold underline text-red-500">
            <a href="https://discord.gg/GyesQwYa">
              Found a bug? Join our discord and report it.
            </a>
          </p>
          <GameModeSwitchModal index={0} pageNumber={pageNumber} location={'/cospotle'} 
            description={TranslationKey.COENIGMA_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={1} pageNumber={pageNumber} location={'/cospotle/team'} 
            description={TranslationKey.COENIGMA_TEAM_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={2} pageNumber={pageNumber} location={'/cospotle/parallel'} 
            description={TranslationKey.COENIGMA_PARALLEL_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={3} pageNumber={pageNumber} location={'/cospotle/bot'} 
            description={TranslationKey.COENIGMA_BOT_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={4} pageNumber={pageNumber} location={'/cospotle/bot/challenge'} 
            description={TranslationKey.COENIGMA_BOT_CHALLENGE_MODE_DESCRIPTION}/>                   
          <GameModeSwitchModal index={5} pageNumber={pageNumber} location={'/cospotle/bot/parallel'} 
            description={TranslationKey.COENIGMA_BOT_PARALLEL_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={6} pageNumber={pageNumber} location={'/cospotle/bot/parallel/challenge'} 
            description={TranslationKey.COENIGMA_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION}/>
        </div>
      )}
      <br></br>
      <div className="navbar-content px-2 short:h-auto">
        <div className="right-icons">
        {(pageNumber > 0 || daily || unlimited || multiplayer) && (
          <button
            type="button"
            className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              if(pageNumber > 0){
                handleDecPageNumber()
              } else {
                handleBlankPageType()
              }
            }}
          >
            {getTranslation(TranslationKey.PREVIOUS)}
          </button>
        )}
        </div>
        <div className="right-icons">
        {(pageNumber < 1 && (daily || unlimited || multiplayer)) && (
          <button
            type="button"
            className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              handleIncPageNumber()
            }}
          >
            {getTranslation(TranslationKey.NEXT)}
          </button>
        )}
        </div>
      </div>
    </BaseModal>
  );
}
