import { Language } from "../constants/language"
import { getIsHorsle, getIsSpeedy, getIsUnlimitedMode, isCustom } from "../App"
import { getToday } from "./dateutils"
import { CharStatus } from "./statuses"
import { getNewGameDate } from "./words"
import { recalcDefinitionAPI } from "../components/navbar/Dictionary"
import { TranslationKey } from "../constants/strings"
import { getTranslation } from "../context/messages"
import { MAX_CHALLENGES } from "../constants/settings"
import { BOTS_INFO, BOT_NAME } from "../constants/bots"
import { isSpeedyGameDone } from "./speedy"
import { addDays } from "date-fns"

const gameStateKey = 'gameState'
const highContrastKey = 'highContrast'
const unlimitedKey = 'unlimited'
const gameDateKey = 'gameDate'
const statusesKey = 'statuses'
const gameStatKey = 'gameStats'
const cookiesModeKey = 'cookiesMode'
const countryKey = 'country'
const titleKey = 'title'
const languageKey = 'language'
const definitionKey = 'definition'
const themeKey = 'theme'
const counterKey = 'counter'
const winsNeededKey = 'winsNeeded'
const speedyStartTimeKey = 'speedyStartTime'
const speedyEndTimeKey = 'speedyEndTime'
const gameStreakKey = 'gameStreak'
const numberOfGamesKey = 'numberOfGames'
const resultsKey = 'results'
const numberOfRowsKey = 'numberOfRows'
const usernameKey = 'username'
const myTurnKey = 'myTurn'
const opponentKey = 'opponent'
const opponentLevelKey = 'opponentLevel'
const botModeKey = 'botMode'
const botChallengeModeKey = 'botChallengeMode'
const customUsernameKey = 'customUsername'
const guessDateKey = 'guessDate'
const teamModeKey = 'teamMode'
const parallelModeKey = 'parallelMode'
const opponentCountryKey = 'opponentCountry'
const coSpotleCustomCodeKey = 'coSpotleCustomGame'
const copiedKey = 'copied'
const customCodeKey = 'customGame'
const customSplitTimeKey = 'customSplitTime'
const newGameModeKey = 'newGameMode1'
const announcementsKey = 'announcements'
const botKey = 'bot'
const indexKey = 'index'
const aprilFoolsSolutions = 'aprilFoolsSolutions'
const shownAprilFoolsKey = 'showsAprilFools'
const resetKey = 'reset'

export type StoredGameState = {
  guesses: string[]
  solutions: string[]
}

function createSimpleKey(key: string):string{
  var newKey = getLanguageText()

  if(getIsSpeedy()){
    newKey = newKey + "speedy"
  }

  if(newKey === ""){
    return key
  }
  
  //the upperCase is need for legacy purposes
  return newKey + key.charAt(0).toUpperCase() + key.slice(1);
}

function createKey(key: string):string {
  var newKey = getLanguageText()

  if(getIsHorsle()){
    newKey = newKey + "dummy"
  }

  if(getIsUnlimitedMode()){
    newKey = newKey + "unlimited"
  }

  if(getIsSpeedy()){
    newKey = newKey + "speedy"
    newKey = newKey + getStoredNumberOfGames()
  }

  if(isCustom()){
    newKey = newKey + 'custom'
  }
  
  if(newKey === ""){
    return key
  }
  
  //the upperCase is need for legacy purposes
  return newKey + key.charAt(0).toUpperCase() + key.slice(1);
}

export const saveGameStateToLocalStorage = (
  gameState: StoredGameState
) => {
  localStorage.setItem(createKey(gameStateKey), JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(createKey(gameStateKey))
  return state ? (JSON.parse(state) as StoredGameState) : null
}

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
  timeoutWins: number
  sixPlusWins: number
  ties: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(createKey(gameStatKey), JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  var stats = localStorage.getItem(createKey(gameStatKey))
  return stats ? (JSON.parse(stats) as GameStats) : null
}

export const setHasSeenNewGameModeInfo = (newGameMode: boolean) => {
  if (newGameMode) {
    localStorage.setItem(newGameModeKey, '3')
  } else {
    localStorage.removeItem(newGameModeKey)
  }
}

export const getHasSeenNewGameModeInfo = () => {
  return localStorage.getItem(newGameModeKey) === '3'
}

export const setHasSeenAnnouncements = (seen: boolean) => {
  if (seen) {
    localStorage.setItem(announcementsKey, '2')
  } else {
    localStorage.removeItem(announcementsKey)
  }
}

export const getHasSeenAnnouncements = () => {
  return localStorage.getItem(announcementsKey) === '2'
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
}

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey)
  return highContrast === '1'
}

export const setStoredIsUnlimitedMode = (isUnlimited: boolean) => {
  if (isUnlimited) {
    localStorage.setItem(createKey(unlimitedKey), '1')
  } else {
    localStorage.removeItem(createKey(unlimitedKey))
  }
}

export const setStoredStatuses = (statuses : Map<number, CharStatus[]>) => {
  const valuesArray = new Array(statuses.size)
  var i = 0;
  for (let entry of Array.from(statuses.entries())) {
    valuesArray[i] = entry[1]
    i++
  }

  localStorage.removeItem(createKey(statusesKey))
  localStorage.setItem(createKey(statusesKey), JSON.stringify(valuesArray))
}

export const getStoredStatuses = () => {
  var state = localStorage.getItem(createKey(statusesKey))
  
  const statuses = new Map<number, CharStatus[]>()
  if(state){
    var i = 0
    for (let value of JSON.parse(state)) {
      statuses.set(i, value)
      i++
    }
  }

  return statuses
}

export const setStoredGameDate = (gameDate: Date, checkWithinBot?:boolean) => {
  if(getIsUnlimitedMode()){
    localStorage.removeItem(createKey(gameDateKey))
    localStorage.setItem(createKey(gameDateKey), gameDate.toString())
  }
}

export const getStoredGameDate = (checkWithinBot?:boolean) => {
  if(getIsUnlimitedMode()){
    const stringGameDate = localStorage.getItem(createKey(gameDateKey))
    if(stringGameDate === null || typeof stringGameDate === "undefined"){
      const gameDate = getNewGameDate(getIsUnlimitedMode())
      setStoredGameDate(gameDate, checkWithinBot)
      return gameDate
    }else{
      return new Date(stringGameDate!)
    }
  } else if (getIsSpeedy()){
    if(isSpeedyGameDone()){
      return addDays(getToday(), 10 * (getStoredNumberOfGames() - 1) * 7) //counter starts at 0
    }

    return addDays(getToday(), 10 * getCounter() * 7)
  }

  return getToday();
}

export const setCookiesMode = (isAll: boolean) => {
  localStorage.setItem(cookiesModeKey, isAll ? 'all' : 'essential')
}

export const getCookiesMode = () => {
  //return localStorage.getItem(cookiesModeKey) === 'all'
  return true
}

export const isRussia = () => {
  return localStorage.getItem(countryKey) === 'Russia' ||
    localStorage.getItem(countryKey) === 'russia' ||
    localStorage.getItem(countryKey) === 'Russian Federation' ||
    localStorage.getItem(countryKey) === 'russian federation'
}

export const isCountrySet = () => {
  return localStorage.getItem(countryKey) !== null
}

export const setCountry = (country: string | null) => {
  if(country !== null){
    localStorage.setItem(countryKey, country)
  }
}

export const setTitle = (title: string) => {
  localStorage.setItem(titleKey, title)
}

export const getTitle = () => {
  const state = localStorage.getItem(titleKey)
  return state ? state : getTranslation(TranslationKey.ENIGMA)
}

export const setLanguage = (language: Language) => {
  localStorage.setItem(languageKey, language)
}

export const setLanguageText = (language: string) => {
  var typedLanguageString = language as keyof typeof Language;
  localStorage.setItem(languageKey, Language[typedLanguageString])
}

export const getLanguage = () => {
  const indexOfS = Object.values(Language).indexOf(getLanguageText() as unknown as Language);
  return Language[Object.keys(Language)[indexOfS] as keyof typeof Language];
}

export const getLanguageText = () => {
  let letLanguageString = localStorage.getItem(languageKey);
  if(letLanguageString === null 
    || typeof letLanguageString === "undefined"
    || letLanguageString === ""){
    setLanguage(Language.ENGLISH)
  }

  return localStorage.getItem(languageKey);
}

export const getLanguageKey = () => {
  const indexOfS = Object.values(Language).indexOf(getLanguageText() as unknown as Language);
  return Object.keys(Language)[indexOfS] as keyof typeof Language
}

export const setDefinition = (definition: string) => {
  localStorage.setItem(createSimpleKey(definitionKey), definition)
}

export const getDefinition = (solution: string) => {
  let definition = localStorage.getItem(createSimpleKey(definitionKey));
  if(definition === null 
    || typeof definition === "undefined"
    || definition === ""){
      recalcDefinitionAPI(solution);
  }
  
  return localStorage.getItem(createSimpleKey(definitionKey))
}

export const setStoredDarkMode = (darkMode: boolean) => {
  localStorage.setItem(themeKey, darkMode ? 'dark' : 'light')
}

export const getStoredDarkMode = () => {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  let darkMode = localStorage.getItem(themeKey)
  if(darkMode === null 
    || typeof darkMode === "undefined"
    || darkMode === ""){
      localStorage.setItem(themeKey, prefersDarkMode ? 'dark' : 'light')
    }

  return localStorage.getItem(themeKey) === 'dark';
}

export const getCounter = () => {
  const state = localStorage.getItem(createKey(counterKey))

  if(state === null){
    setCounter(0)
    return 0
  }
  return +state
}

export const setCounter = (counter: number) => {
  localStorage.removeItem(createKey(counterKey))
  localStorage.setItem(createKey(counterKey), counter.toString())
}

export function incCounter() {
  setCounter(getCounter() + 1)
}

export function decCounter() {
  setCounter(getCounter() - 1)
}

export const setWinsNeeded = (wins: number) => {
  localStorage.removeItem(createKey(winsNeededKey))
  localStorage.setItem(createKey(winsNeededKey), wins.toString())
}

export const setSpeedyStartTime = (startTime: number) => {
  localStorage.removeItem(createKey(speedyStartTimeKey))
  localStorage.setItem(createKey(speedyStartTimeKey), JSON.stringify(startTime))
}

export const getSpeedyStartTime = () => {
  const state = localStorage.getItem(createKey(speedyStartTimeKey))

  if(state === null){
    setSpeedyStartTime(0)
    return 0
  }

  return +state
}

export const setSpeedyEndTime = (endTime: number) => {
  localStorage.removeItem(createKey(speedyEndTimeKey))
  localStorage.setItem(createKey(speedyEndTimeKey), JSON.stringify(endTime))
}

export const getSpeedyEndTime = () => {
  const state = localStorage.getItem(createKey(speedyEndTimeKey))

  if(state === null || state === '0'){
    const newTime = Math.floor(Date.now() / 1000)
    setSpeedyEndTime(newTime)

    return newTime
  }

  return +state
}

export const setGameStreak = (gameStreak: number[]) => {
  localStorage.removeItem(createKey(gameStreakKey))
  localStorage.setItem(createKey(gameStreakKey), JSON.stringify(gameStreak))
}

export const getGameStreak = () => {
  const state = localStorage.getItem(createKey(gameStreakKey))
  return state ? (JSON.parse(state) as number[]) : []
}

export const addGameStreakWin = (guesses: number) => {
  setGameStreak([...getGameStreak(), guesses])
}
  
export const addGameStreakLoss = () => {
  setGameStreak([...getGameStreak(), -1])
}

export const setStoredNumberOfGames = (gameStreak: number) => {
  localStorage.removeItem(createSimpleKey(numberOfGamesKey))
  localStorage.setItem(createSimpleKey(numberOfGamesKey), JSON.stringify(gameStreak))
}

export const getStoredNumberOfGames = () => {
  if(getIsSpeedy() && !getIsUnlimitedMode()){
    return 10
  } else if (getIsSpeedy() && !getIsUnlimitedMode()){
    return 5
  }
  
  const state = localStorage.getItem(createSimpleKey(numberOfGamesKey))
  if(state){
    return (JSON.parse(state) as number)
  } else if(getStoredBotChallengeMode()){
    return 3
  }
  
  return 25
}

export const setResults = (results: number[]) => {
  localStorage.removeItem(createKey(resultsKey))
  localStorage.setItem(createKey(resultsKey), JSON.stringify(results))
}

export const getResults = () => {
  const state = localStorage.getItem(createKey(resultsKey))
  return state ? (JSON.parse(state) as number[]) : []
}

export const setStoredNumberOfRows = (rows: number) => {
  localStorage.removeItem(createSimpleKey(numberOfRowsKey))
  localStorage.setItem(createSimpleKey(numberOfRowsKey), JSON.stringify(rows))
}

export const getStoredNumberOfRows = () => {
  const state = localStorage.getItem(createSimpleKey(numberOfRowsKey))
  return state ? (JSON.parse(state) as number) : 1
}

export const setStoredUsername = (username: string) => {
  localStorage.removeItem(createKey(usernameKey))
  localStorage.setItem(createKey(usernameKey), username)
}

export const getStoredUsername = () => {
  const state = localStorage.getItem(createKey(usernameKey))
  if(state === null || state === undefined){
    var newUsername = Date.now().toString()
    newUsername = newUsername + Math.floor(100000000000 + Math.random() * 900000000000).toString() + getStoredCustomUsername()
    setStoredUsername(newUsername)
    return newUsername
  }

  return state
}

export const setStoredMyTurn = (myTurn: boolean) => {
  localStorage.removeItem(createKey(myTurnKey))
  localStorage.setItem(createKey(myTurnKey), JSON.stringify(myTurn))
}

export const getStoredMyTurn = () => {
  const state = localStorage.getItem(createKey(myTurnKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredOpponent = (opponent: string) => {
  localStorage.removeItem(createKey(opponentKey))
  localStorage.setItem(createKey(opponentKey), opponent)
}

export const getStoredOpponent = () => {
  const state = localStorage.getItem(createKey(opponentKey))
  return state ? state : ''
}

export const setStoredOpponentLevel = (level: number) => {
  if(level < 1){
    level = 1
  } else if (level > 10){
    level = 10
  }

  localStorage.removeItem(createKey(opponentLevelKey))
  localStorage.setItem(createKey(opponentLevelKey), level.toString())
}

export const getStoredOpponentLevel = () => {
  const state = localStorage.getItem(createKey(opponentLevelKey))
  return state ? +state : 1
}

export const setBot = (bot: BOT_NAME) => {
  localStorage.setItem(createKey(botKey), bot)
}

export const getBot = () => {
  const botStr = localStorage.getItem(createKey(botKey))
  var bot = BOT_NAME.ENIGMA
  if(botStr !== null && typeof botStr !== "undefined" && botStr !== ""){
    bot = botStr as BOT_NAME
  } else {
    setBot(BOT_NAME.ENIGMA)
  }

  return BOTS_INFO[bot];
}

export const setStoredBotMode = (botMode: boolean) => {
  localStorage.removeItem(createSimpleKey(botModeKey))
  localStorage.setItem(createSimpleKey(botModeKey), JSON.stringify(botMode))
}

export const getStoredBotMode = () => {
  const state = localStorage.getItem(createSimpleKey(botModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredBotChallengeMode = (botChallengeMode: boolean) => {
  localStorage.removeItem(createSimpleKey(botChallengeModeKey))
  localStorage.setItem(createSimpleKey(botChallengeModeKey), JSON.stringify(botChallengeMode))
}

export const getStoredBotChallengeMode = () => {
  const state = localStorage.getItem(createSimpleKey(botChallengeModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const getStoredPlainCoSpotle = () => {
  return !getStoredTeamMode() && !getStoredParallelMode()
}

export const setStoredCustomUsername = (customUsername: string) => {
  localStorage.removeItem(createSimpleKey(customUsernameKey))
  localStorage.setItem(createSimpleKey(customUsernameKey), customUsername)
}

export const getStoredCustomUsername = () => {
  const state = localStorage.getItem(createSimpleKey(customUsernameKey))
  return state ? state : ''
}

export const setStoredGuessDate = (gameDate: Date | undefined) => {
  localStorage.removeItem(createKey(guessDateKey))

  if(gameDate !== undefined){
    localStorage.setItem(createKey(guessDateKey), gameDate.toString())
  }
}

export const getStoredGuessDate = () => {
  const stringGameDate = localStorage.getItem(createKey(guessDateKey))
  if(stringGameDate === null || typeof stringGameDate === "undefined"){
    return undefined
  }else{
    return new Date(stringGameDate!)
  }
}

export const setStoredTeamMode = (teamMode: boolean) => {
  localStorage.removeItem(createSimpleKey(teamModeKey))
  localStorage.setItem(createSimpleKey(teamModeKey), JSON.stringify(teamMode))
}

export const getStoredTeamMode = () => {
  const state = localStorage.getItem(createSimpleKey(teamModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredParallelMode = (teamMode: boolean) => {
  localStorage.removeItem(createSimpleKey(parallelModeKey))
  localStorage.setItem(createSimpleKey(parallelModeKey), JSON.stringify(teamMode))
}

export const getStoredParallelMode = () => {
  const state = localStorage.getItem(createSimpleKey(parallelModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredOpponentCountry = (country: string) => {
  localStorage.removeItem(createKey(opponentCountryKey))
  localStorage.setItem(createKey(opponentCountryKey), country)
}

export const getStoredOpponentCountry = () => {
  const state = localStorage.getItem(createKey(opponentCountryKey))
  return state ? state : ''
}

export const deleteStoredCustomCode = () => {
  localStorage.removeItem(createKey(coSpotleCustomCodeKey))
}

export const setStoredCoSpotleCustomCode = (customGame: string) => {
  if(customGame.includes('/')){
    const urlArray = customGame.split('/')
    customGame = urlArray[urlArray.length - 1]

    if(!customGame.includes('&')){
      return
    }
  }

  localStorage.removeItem(createKey(coSpotleCustomCodeKey))
  localStorage.setItem(createKey(coSpotleCustomCodeKey), customGame)
}

export const getStoredCoSpotleCustomCode = () => {
  const state = localStorage.getItem(createKey(coSpotleCustomCodeKey))
  return state ? state : null
}

export const setStoredCopiedMode = (copied: boolean) => {
  localStorage.removeItem(createSimpleKey(copiedKey))
  localStorage.setItem(createSimpleKey(copiedKey), JSON.stringify(copied))
}

export const getStoredCopiedMode = () => {
  const state = localStorage.getItem(createSimpleKey(copiedKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredCustomInfo = (customGame: string) => {
  const urlArray = customGame.split('/&/')
  customGame = urlArray[urlArray.length - 1]
  
  if(customGame.endsWith('/custom')){
    return
  }

  if(!customGame.includes('&')){
    localStorage.removeItem(createKey(customCodeKey))
    localStorage.setItem(createKey(customCodeKey), customGame)

    return
  }

  const gameArray = customGame.split('&')
  const solutions:string[] = []//decrypt(gameArray[0])!
  const guesses:string[] = []
  const statuses = new Map<number, CharStatus[]>()
  var binary = parseInt(gameArray[1], 10).toString(2)

  while(binary.length < MAX_CHALLENGES * solutions[0].length){
    binary = '0' + binary
  }

  localStorage.removeItem(createKey(customCodeKey))
  localStorage.setItem(createKey(customCodeKey), JSON.stringify(solutions))

  for(var i = 0; i < MAX_CHALLENGES; i++){
    for(var j = 0; j < solutions.length; j++){
      if(statuses.get(i) === undefined){
        statuses.set(i, Array(solutions[i].length))
      }

      if(binary.charAt(j + i * (solutions[i].length)) === '1'){
        statuses.get(i)![j] = 'hidden'
      }
    }
  }
  
  setStoredCustomSplitTime(binary)
  setStoredStatuses(statuses)
  saveGameStateToLocalStorage({ guesses, solutions })
}


export const getStoredCustomSolution = () => {
  const state = localStorage.getItem(createKey(customCodeKey))
  return state ? state : ''
}

export const setStoredCustomSplitTime = (splitTime: string) => {
  const array = []

  for(var i = 0; i < splitTime.length; i++){
    array[i] = +splitTime.charAt(i)
  }

  localStorage.removeItem(createKey(customSplitTimeKey))
  localStorage.setItem(createKey(customSplitTimeKey), JSON.stringify(array))
}

export const getStoredCustomSplitTime = () => {
  const state = localStorage.getItem(createKey(customSplitTimeKey))
  return state ? (JSON.parse(state) as number[]) : []
}

export const setStoredIndex = (index: number) => {
  localStorage.removeItem(createKey(indexKey))
  localStorage.setItem(createKey(indexKey), JSON.stringify(index))
}

export const getStoredIndex = () => {
  const state = localStorage.getItem(createKey(indexKey))
  return state ?  +state : 0
}

export const setStoredAprilFoolsSolutions = (remaining: string) => {
  const total = [...getStoredAprilFoolsSolutions(), remaining]
  
  localStorage.removeItem(createKey(aprilFoolsSolutions))
  localStorage.setItem(createKey(aprilFoolsSolutions), JSON.stringify(total))
}

export const getStoredAprilFoolsSolutions = () => {
  const state = localStorage.getItem(createKey(aprilFoolsSolutions))
  return state ? (JSON.parse(state) as string[]) : []
}

export const resetStoredAprilFoolsSolutions = () => {
  localStorage.removeItem(createKey(aprilFoolsSolutions))
}

export const getStoredShownAprilFools = () => {
  return localStorage.getItem(shownAprilFoolsKey) === '1'
}

export const setStoredShownAprilFools = (shown: boolean) => {
  if (shown) {
    localStorage.setItem(shownAprilFoolsKey, '1')
  } else {
    localStorage.removeItem(shownAprilFoolsKey)
  }
}

export const getStoredRestart = () => {
  return localStorage.getItem(resetKey) === '1'
}

export const setStoredRestart = (restart: boolean) => {
  if (restart) {
    localStorage.setItem(resetKey, '1')
  } else {
    localStorage.removeItem(resetKey)
  }
}