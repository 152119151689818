import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'
import { CharStatus, Move } from '../../lib/statuses'

type Props = {
  rowIndex: number
  guess: string
  revealing: Move
  statuses: Map<number, CharStatus[]>
  handleMoveLeft?: Function
  handleMoveRight?: Function
  revealIndex?: number
}

export const CompletedRow = ({
    rowIndex, 
    guess, 
    revealing, 
    statuses,  
    handleMoveLeft,
    handleMoveRight,
    revealIndex
  }: Props) => {
  const splitGuess = unicodeSplit(guess)

  var rowStatuses = new Array(guess.length)
  if(typeof statuses.get(rowIndex) !== "undefined"){
    rowStatuses = statuses.get(rowIndex)!
  }

  return (
    <div className="mb-1 flex justify-center">
      <Cell
        rowIndex={rowIndex}
        key={rowIndex + 105}
        value={'⇦'}
        status={'absent'}
        handleMoveLeft={handleMoveLeft}
        revealing={revealing}
        revealIndex={revealIndex}
      />
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          rowIndex={rowIndex}
          columnIndex={i}
          revealIndex={revealIndex}
          value={letter}
          status={rowStatuses[i]}
          revealing={revealing}
        />
      ))}
      <Cell
        rowIndex={rowIndex}
        key={rowIndex + 205}
        revealIndex={revealIndex}
        value={'⇨'}
        status={'absent'}
        handleMoveRight={handleMoveRight}
        revealing={revealing}
      />
    </div>
  )
}
